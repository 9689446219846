export interface IProject {
    displayName: string;
    logoType: any;
    favicon: any;
    secondaryColor: string;
};

export const onro : IProject = {
    displayName: "Onro",
    logoType: '/saas/onro/logo.png',
    favicon: '/saas/onro/favicon.png',
    secondaryColor: "#1080FF"
}

export const pickup : IProject = {
    displayName: "Pickup",
    logoType: '/saas/pickup/logo.png',
    favicon: '/saas/pickup/favicon.png',
    secondaryColor: "#ffce00"
}

export const acistant : IProject = {
    displayName: "Acistant",
    logoType: '/saas/acistant/logo.png',
    favicon: '/saas/acistant/favicon.png',
    secondaryColor: "#1080FF"
}

export const asankar : IProject = {
    displayName: "Asankar",
    logoType: '/saas/asankar/logo.png',
    favicon: '/saas/asankar/favicon.png',
    secondaryColor: "#1080FF"
}

export const deliro : IProject = {
    displayName: "Deliro",
    logoType: '/saas/deliro/logo.svg',
    favicon: '/saas/deliro/favicon.png',
    secondaryColor: "#1080FF"
}

export const doorpost : IProject = {
    displayName: "Doorpost",
    logoType: '/saas/doorpost/logo.png',
    favicon: '/saas/doorpost/favicon.png',
    secondaryColor: "#005EB8"
}


export const hellocourier : IProject = {
    displayName: "Hello Courier",
    logoType: '/saas/hellocourier/logo.png',
    favicon: '/saas/hellocourier/favicon.png',
    secondaryColor: "#88A31E"
}

export const emrazgo : IProject = {
    displayName: "Emrazgo",
    logoType: '/saas/emrazgo/logo.png',
    favicon: '/saas/emrazgo/favicon.png',
    secondaryColor: "#D03227"
}

export const oto : IProject = {
    displayName: "OTO",
    logoType: '/saas/oto/logo.png',
    favicon: '/saas/oto/favicon.png',
    secondaryColor: "#0066FF"
}

export const vee : IProject = {
    displayName: "Vee",
    logoType: '/saas/vee/logo.png',
    favicon: '/saas/vee/favicon.png',
    secondaryColor: "#4067B1"
}

export const catringo : IProject = {
    displayName: "Catringo",
    logoType: '/saas/catringo/logo.png',
    favicon: '/saas/catringo/favicon.png',
    secondaryColor: "#004900"
}

export const loco : IProject = {
    displayName: "Loco",
    logoType: '/saas/loco/logo.png',
    favicon: '/saas/loco/favicon.png',
    secondaryColor: "#1080FF"
}

export const hio : IProject = {
    displayName: "Hio",
    logoType: '/saas/hio/logo.png',
    favicon: '/saas/hio/favicon.png',
    secondaryColor: "#DD1E30"
}

export const fabbud : IProject = {
    displayName: "Fab bud",
    logoType: '/saas/fabbud/logo.png',
    favicon: '/saas/fabbud/favicon.png',
    secondaryColor: "#ffce00"
}


export const geenstress : IProject = {
    displayName: "Geen Stress Amsterdam",
    logoType: '/saas/geenstress/logo.png',
    favicon: '/saas/geenstress/favicon.png',
    secondaryColor: "#FF0000"
}

export const pickngo : IProject = {
    displayName: "Pick & Go ",
    logoType: '/saas/pickngo/logo.png',
    favicon: '/saas/pickngo/favicon.png',
    secondaryColor: "#1080FF"
}

export const wadelivery : IProject = {
    displayName: "wa! delivery ",
    logoType: '/saas/wadelivery/logo.png',
    favicon: '/saas/wadelivery/favicon.png',
    secondaryColor: "#54264E"
}

export const zoomcurier : IProject = {
    displayName: "Zoom Curier",
    logoType: '/saas/zoomcurier/logo.png',
    favicon: '/saas/zoomcurier/favicon.png',
    secondaryColor: "#EB2328"
}


export const swiftee : IProject = {
    displayName: "Swiftee",
    logoType: '/saas/swiftee/logo.png',
    favicon: '/saas/swiftee/favicon.png',
    secondaryColor: "#FFD700"
}

export const sanic : IProject = {
    displayName: "Sanic",
    logoType: '/saas/sanic/logo.png',
    favicon: '/saas/sanic/favicon.png',
    secondaryColor: "#F6931E"
}

export const munchi : IProject = {
    displayName: "Munchi",
    logoType: '/saas/munchi/logo.png',
    favicon: '/saas/munchi/favicon.png',
    secondaryColor: "#FF3537"
}

export const quicksend : IProject = {
    displayName: "QuickSend",
    logoType: '/saas/quicksend/logo.png',
    favicon: '/saas/quicksend/favicon.png',
    secondaryColor: "#003087"
}


export const beswifty : IProject = {
    displayName: "BESwifty",
    logoType: '/saas/beswifty/logo.png',
    favicon: '/saas/beswifty/favicon.png',
    secondaryColor: "#00ABD9"
}

export const vroomvroom : IProject = {
    displayName: "VroomVroom",
    logoType: '/saas/vroomvroom/logo.png',
    favicon: '/saas/vroomvroom/favicon.png',
    secondaryColor: "#C60C1E"
}

export const askeriglobal : IProject = {
    displayName: "ASKERI GLOBAL",
    logoType: '/saas/askeriglobal/logo.png',
    favicon: '/saas/askeriglobal/favicon.png',
    secondaryColor: "#000000"
}

export const dmoapp : IProject = {
    displayName: "DMO",
    logoType: '/saas/dmoapp/logo.png',
    favicon: '/saas/dmoapp/favicon.png',
    secondaryColor: "#F2E201"
}

export const dlxapp : IProject = {
    displayName: "Diamond Logistics",
    logoType: '/saas/dlxapp/logo.png',
    favicon: '/saas/dlxapp/favicon.png',
    secondaryColor: "#E64296"
}

export const strikeafrica : IProject = {
    displayName: "Strike Delivery",
    logoType: '/saas/strikeafrica/logo.png',
    favicon: '/saas/strikeafrica/favicon.png',
    secondaryColor: "#423D98"
}

export const superkiwilogistics : IProject = {
    displayName: "SuperKiwi Logistics",
    logoType: '/saas/superkiwilogistics/logo.png',
    favicon: '/saas/superkiwilogistics/favicon.png',
    secondaryColor: "#34ABE1"
}

export const annanow : IProject = {
    displayName: "AnnanowLog",
    logoType: '/saas/annanow/logo.png',
    favicon: '/saas/annanow/favicon.png',
    secondaryColor: "#000000"
}

export const weedeliverapp : IProject = {
    displayName: "Wee Deliver",
    logoType: '/saas/weedeliverapp/logo.png',
    favicon: '/saas/weedeliverapp/favicon.png',
    secondaryColor: "#FDBC4C"
}

export const kwikdash : IProject = {
    displayName: "Wee Deliver",
    logoType: '/saas/kwikdash/logo.png',
    favicon: '/saas/kwikdash/favicon.png',
    secondaryColor: "#F7BA00"
}

export const rybitCityspider : IProject = {
    displayName: "Rybit Cityspider",
    logoType: '/saas/rybitCityspider/logo.png',
    favicon: '/saas/rybitCityspider/favicon.png',
    secondaryColor: "#0A2240"
}

export const jrun : IProject = {
    displayName: "J-RUN",
    logoType: '/saas/jrun/logo.png',
    favicon: '/saas/jrun/favicon.png',
    secondaryColor: "#00B2DF"
}

export const sprint : IProject = {
    displayName: "Sprint",
    logoType: '/saas/sprint/logo.png',
    favicon: '/saas/sprint/favicon.png',
    secondaryColor: "#FF007E"
}

export const flech : IProject = {
    displayName: "Flech",
    logoType: '/saas/flech/logo.png',
    favicon: '/saas/flech/favicon.png',
    secondaryColor: "#000406"
}

export const aofferdelivery : IProject = {
    displayName: "AOffer Delivery",
    logoType: '/saas/aofferdelivery/logo.png',
    favicon: '/saas/aofferdelivery/favicon.png',
    secondaryColor: "#FECD06"
}


export const caseworld : IProject = {
    displayName: "Case World",
    logoType: '/saas/caseworld/logo.png',
    favicon: '/saas/caseworld/favicon.png',
    secondaryColor: "#00003e"
}

export const carrecovery911 : IProject = {
    displayName: "911 Car Recovery",
    logoType: '/saas/911carrecovery/logo.png',
    favicon: '/saas/911carrecovery/favicon.png',
    secondaryColor: "#FCCE1B"
}

export const elatreqi : IProject = {
    displayName: "3latreqi",
    logoType: '/saas/3latreqi/logo.png',
    favicon: '/saas/3latreqi/favicon.png',
    secondaryColor: "#008037"
}

export const rappid : IProject = {
    displayName: "Rappid",
    logoType: '/saas/rappid/logo.png',
    favicon: '/saas/rappid/favicon.png',
    secondaryColor: "#FF0000"
}

export const getitpicked : IProject = {
    displayName: "Get It Picked",
    logoType: '/saas/getitpicked/logo.png',
    favicon: '/saas/getitpicked/favicon.png',
    secondaryColor: "#3b5998"
}

export const floti : IProject = {
    displayName: "Floti",
    logoType: '/saas/floti/logo.png',
    favicon: '/saas/floti/favicon.png',
    secondaryColor: "#0071FF"
}

export const famvalet : IProject = {
    displayName: "F.A.M. Valet",
    logoType: '/saas/famvalet/logo.png',
    favicon: '/saas/famvalet/favicon.png',
    secondaryColor: "#A3A5A8"
}


export const automater : IProject = {
    displayName: "Automater",
    logoType: '/saas/automater/logo.png',
    favicon: '/saas/automater/favicon.png',
    secondaryColor: "#94c57f"
}

export const swiftitexpress : IProject = {
    displayName: "Swiftit Express",
    logoType: '/saas/swiftitexpress/logo.png',
    favicon: '/saas/swiftitexpress/favicon.png',
    secondaryColor: "#7DC9FC"
}

export const kerryexpress : IProject = {
    displayName: "Kerry Express",
    logoType: '/saas/kerryexpress/logo.png',
    favicon: '/saas/kerryexpress/favicon.png',
    secondaryColor: "#F37021"
}


export const viahero : IProject = {
    displayName: "Viahero",
    logoType: '/saas/viahero/logo.png',
    favicon: '/saas/viahero/favicon.png',
    secondaryColor: "#22429C"
}


export const nwacourier : IProject = {
    displayName: "NWA Courier",
    logoType: '/saas/nwacourier/logo.png',
    favicon: '/saas/nwacourier/favicon.png',
    secondaryColor: "#ff7f00"
}

export const flyto : IProject = {
    displayName: "Flyto",
    logoType: '/saas/flyto/logo.png',
    favicon: '/saas/flyto/favicon.png',
    secondaryColor: "#35327F"
}

export const bristolcountycouriers : IProject = {
    displayName: "Bristol County Courier",
    logoType: '/saas/bristolcountycouriers/logo.png',
    favicon: '/saas/bristolcountycouriers/favicon.png',
    secondaryColor: "#d9d9d9"
}

export const kerryexpresstest : IProject = {
    displayName: "Kerry Express",
    logoType: '/saas/kerryexpresstest/logo.png',
    favicon: '/saas/kerryexpresstest/favicon.png',
    secondaryColor: "#F37021"
}

export const clarabiofuels : IProject = {
    displayName: "Clara Biofuels",
    logoType: '/saas/clarabiofuels/logo.png',
    favicon: '/saas/clarabiofuels/favicon.png',
    secondaryColor: "#54A7DB"
}

export const scooty : IProject = {
    displayName: "Scooty",
    logoType: '/saas/scooty/logo.png',
    favicon: '/saas/scooty/favicon.png',
    secondaryColor: "#61AE46"
}

export const nobodi : IProject = {
    displayName: "Nobodi",
    logoType: '/saas/nobodi/logo.png',
    favicon: '/saas/nobodi/favicon.png',
    secondaryColor: "#242424"
}

export const gripx : IProject = {
    displayName: "GRIPX",
    logoType: '/saas/gripx/logo.png',
    favicon: '/saas/gripx/favicon.png',
    secondaryColor: "#FE5900"
}

export const bigticit : IProject = {
    displayName: "BIG TIC-IT",
    logoType: '/saas/bigticit/logo.png',
    favicon: '/saas/bigticit/favicon.png',
    secondaryColor: "#fb2510"
}


export const twentyFourCouriers : IProject = {
    displayName: "24 Couriers",
    logoType: '/saas/24couriers/logo.png',
    favicon: '/saas/24couriers/favicon.png',
    secondaryColor: "#fa232a"
}


export const jenatrucks : IProject = {
    displayName: "Jena Trucks",
    logoType: '/saas/jenatrucks/logo.png',
    favicon: '/saas/jenatrucks/favicon.png',
    secondaryColor: "##242424"
}

export const geshikt : IProject = {
    displayName: "Geshikt",
    logoType: '/saas/geshikt/logo.png',
    favicon: '/saas/geshikt/favicon.png',
    secondaryColor: "#395594"
}

export const cryogenicsgroup : IProject = {
    displayName: "Cryogenics Group",
    logoType: '/saas/cryogenicsgroup/logo.png',
    favicon: '/saas/cryogenicsgroup/favicon.png',
    secondaryColor: "#184ca3"
}

export const skiptheq : IProject = {
    displayName: "SkiptheQ",
    logoType: '/saas/skiptheq/logo.png',
    favicon: '/saas/skiptheq/favicon.png',
    secondaryColor: "#008EFE"
}

export const parcelbeep : IProject = {
    displayName: "Parcel Beep",
    logoType: '/saas/parcelbeep/logo.png',
    favicon: '/saas/parcelbeep/favicon.png',
    secondaryColor: "#F5B201"
}

export const maydaycourier : IProject = {
    displayName: "Mayday Courier Delivery",
    logoType: '/saas/maydaycourier/logo.png',
    favicon: '/saas/maydaycourier/favicon.png',
    secondaryColor: "#0551AF"
}

export const pinkexpress : IProject = {
    displayName: "Pink Express",
    logoType: '/saas/pinkexpress/logo.png',
    favicon: '/saas/pinkexpress/favicon.png',
    secondaryColor: "#EA2E81"
}

export const reflexcourier : IProject = {
    displayName: "Reflex courier",
    logoType: '/saas/reflexcourier/logo.png',
    favicon: '/saas/reflexcourier/favicon.png',
    secondaryColor: "#710408"
}

export const snapbox : IProject = {
    displayName: "Snapbox",
    logoType: '/saas/snapbox/logo.png',
    favicon: '/saas/snapbox/favicon.png',
    secondaryColor: "#be0401"
}


export const anypoint : IProject = {
    displayName: "AnyPoint",
    logoType: '/saas/anypoint/logo.png',
    favicon: '/saas/anypoint/favicon.png',
    secondaryColor: "#662D90"
}

export const privitridesdelivery : IProject = {
    displayName: "Privit Rides & Delivery",
    logoType: '/saas/privitridesdelivery/logo.png',
    favicon: '/saas/privitridesdelivery/favicon.png',
    secondaryColor: "#173865"
}

export const Itsthetruckingforme : IProject = {
    displayName: "Its The Trucking Forme",
    logoType: '/saas/Itsthetruckingforme/logo.png',
    favicon: '/saas/Itsthetruckingforme/favicon.png',
    secondaryColor: "#ff6bb9"
}

export const beyonddelivery : IProject = {
    displayName: "BEYOND DELIVERY",
    logoType: '/saas/beyonddelivery/logo.png',
    favicon: '/saas/beyonddelivery/favicon.png',
    secondaryColor: "#d5e594"
}

export const mannaev : IProject = {
    displayName: "Manna EV",
    logoType: '/saas/mannaev/logo.png',
    favicon: '/saas/mannaev/favicon.ico',
    secondaryColor: "#FFCD30"
}

export const landeber : IProject = {
    displayName: "Landeber",
    logoType: '/saas/landeber/logo.png',
    favicon: '/saas/landeber/favicon.ico',
    secondaryColor: "#b6994c"
}

export const joradelivery : IProject = {
    displayName: "JORA Delivery",
    logoType: '/saas/joradelivery/logo.png',
    favicon: '/saas/joradelivery/favicon.ico',
    secondaryColor: "#3E7888"
}

export const gopanda : IProject = {
    displayName: "Go Panda",
    logoType: '/saas/gopanda/logo.png',
    favicon: '/saas/gopanda/favicon.ico',
    secondaryColor: "#081458"
}

export const couriersexpress : IProject = {
    displayName: "Couriers Express",
    logoType: '/saas/couriersexpress/logo.png',
    favicon: '/saas/couriersexpress/favicon.ico',
    secondaryColor: "#CC0000"
}


export const swishr : IProject = {
    displayName: "Couriers Express",
    logoType: '/saas/swishr/logo.png',
    favicon: '/saas/swishr/favicon.ico',
    secondaryColor: "#7f44f8"
}

export const kiosken : IProject = {
    displayName: "Kiosk 24/7",
    logoType: '/saas/kiosken/logo.png',
    favicon: '/saas/kiosken/favicon.ico',
    secondaryColor: "#1a75eb"
}

export const fasttrack : IProject = {
    displayName: "Fast Track",
    logoType: '/saas/fasttrack/logo.png',
    favicon: '/saas/fasttrack/favicon.ico',
    secondaryColor: "#FCC300"
}

export const ondelivery : IProject = {
    displayName: "On Delivery",
    logoType: '/saas/ondelivery/logo.png',
    favicon: '/saas/ondelivery/favicon.ico',
    secondaryColor: "#1fff00"
}

export const kwikr : IProject = {
    displayName: "Kwikr",
    logoType: '/saas/kwikr/logo.png',
    favicon: '/saas/kwikr/favicon.ico',
    secondaryColor: "#000000"
}

export const loadmanexpress : IProject = {
    displayName: "LOADMAN EXPRESS",
    logoType: '/saas/loadmanexpress/logo.png',
    favicon: '/saas/loadmanexpress/favicon.ico',
    secondaryColor: "#1b1f21"
}

export const eaglepost : IProject = {
    displayName: "Eagle Post",
    logoType: '/saas/eaglepost/logo.png',
    favicon: '/saas/eaglepost/favicon.ico',
    secondaryColor: "#FFCC00"
}

export const oneThousandSolutions : IProject = {
    displayName: "1000 Solutions",
    logoType: '/saas/1000solutions/logo.png',
    favicon: '/saas/1000solutions/favicon.ico',
    secondaryColor: "#5F6062"
}

export const pickndrop : IProject = {
    displayName: "PickNDrop",
    logoType: '/saas/pickndrop/logo.png',
    favicon: '/saas/pickndrop/favicon.ico',
    secondaryColor: "#552583"
}

export const ezdelivery : IProject = {
    displayName: "EzDelivery",
    logoType: '/saas/ezdelivery/logo.png',
    favicon: '/saas/ezdelivery/favicon.ico',
    secondaryColor: "#073692"
}

export const gettydispatch : IProject = {
    displayName: "Getty Dispatch",
    logoType: '/saas/gettydispatch/logo.png',
    favicon: '/saas/gettydispatch/favicon.ico',
    secondaryColor: "#007bff"
}

export const deliveryondemand : IProject = {
    displayName: "Delivery OnDemand",
    logoType: '/saas/deliveryondemand/logo.png',
    favicon: '/saas/deliveryondemand/favicon.ico',
    secondaryColor: "#FF8C00"
}

export const blink : IProject = {
    displayName: "Blink DSP",
    logoType: '/saas/blink/logo.png',
    favicon: '/saas/blink/favicon.ico',
    secondaryColor: "#283673"
}

export const ThreeDegreesDelivery : IProject = {
    displayName: "3 Degrees Delivery",
    logoType: '/saas/odos/logo.png',
    favicon: '/saas/odos/favicon.ico',
    secondaryColor: "#47A2B5"
}

export const Aurel : IProject = {
    displayName: "Aurel Logistics",
    logoType: '/saas/aurel/logo.png',
    favicon: '/saas/aurel/favicon.ico',
    secondaryColor: "#FF6B00"
}

export const jpcarrier : IProject = {
    displayName: "JP Carrier",
    logoType: '/saas/jpcarrier/logo.png',
    favicon: '/saas/jpcarrier/favicon.ico',
    secondaryColor: "#EB5F52"
}

export const ryderx : IProject = {
    displayName: "RyderX",
    logoType: '/saas/ryderx/logo.png',
    favicon: '/saas/ryderx/favicon.ico',
    secondaryColor: "#000000"
}


export const pickmydrop : IProject = {
    displayName: "Pick My Drop",
    logoType: '/saas/pickmydrop/logo.png',
    favicon: '/saas/pickmydrop/favicon.ico',
    secondaryColor: "#000000"
}

export const jumbez : IProject = {
    displayName: "Jumbez",
    logoType: '/saas/jumbez/logo.png',
    favicon: '/saas/jumbez/favicon.ico',
    secondaryColor: "#0C6E77"
}