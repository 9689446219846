import { getSetting } from '@app/utils/project';
import {BallsWarper , Balls} from './loading.styles';

const color = getSetting().secondaryColor

export const Loader = () => {
  return (
    <BallsWarper>
      <Balls color={color}/>
      <Balls color={color}/>
      <Balls color={color}/>
    </BallsWarper>
  );
};
