import { RootState } from "@app/store";
import { getSetting } from "@app/utils/project";
import { memo } from "react";
import { useSelector } from "react-redux";
import {
  StyledLogoImage,
  StyledHeader,
  StyledSpan
} from "./mainHeader.styles";

type MainHeaderProps = {};

export const MainHeader = ({}: MainHeaderProps) => {

  const name = useSelector((state: RootState) => state.customer.name);

  return (
    <StyledHeader>
      <StyledLogoImage src={getSetting().logoType} alt="Logo" />
      <StyledSpan> {name} </StyledSpan>
    </StyledHeader>
  );
};

export const MemoMainHeader = memo(MainHeader)
