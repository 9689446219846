
import { getSetting } from '@app/utils/project';
import { Helmet } from 'react-helmet'

type HelmetContainerProps = {};

export const HelmetContainer = ({}: HelmetContainerProps) => {

  console.log(getSetting());
  
  const {secondaryColor, favicon , displayName} = getSetting();

  return (
    <>
    <Helmet>
        <title>{displayName}</title>
        <meta name={displayName} content='Track Page' />
        <link rel="icon" type="image/png" href={favicon} sizes="64x64" />
        <meta name="theme-color" content={secondaryColor} />
    </Helmet>
    </>
    
  );
};
