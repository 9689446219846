import { useEffect, useState } from "react";
import { MemoMainBottom } from "@app/layout/main-bottom";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSetting } from "@app/store/setting.slice";
import { setCustomer } from "@app/store/customer.slice";
import { setPickup, setDropOffs } from "@app/store/marker.slice";
import {
  setOrderType,
  setOrderStatus,
  setOrderDriver,
  setOrderId,
  setOrderEvents,
  setRealETA,
} from "@app/store/order.slice";
import { LocalData } from "@app/services/localStorage";
import { localStorageConst } from "@app/utils/constants";
import { MemoMapSection } from "@app/layout/map-section";
import { setService, setVehicle } from "@app/store/service.slice";
import { MemoETA } from "@app/components/ETA";
import { IndividualOrder } from "@app/services/api";
import { MemoMainHeader } from "@app/components/Main-header";
import { Loader }  from "@app/components/loading";

export const Main = () => {
  let params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const language = searchParams.get("language")


  const [loading, setLoading] = useState(true);
  const [OrderType, setOrderTypeHook] = useState("");
  const [vehicleTypeIcon, setVehicleTypeIcon] = useState<string | undefined>(
    undefined
  );

  const dispatch = useDispatch();
  let navigate = useNavigate();

  useEffect(() => {
    new IndividualOrder().trackOrder({ orderCode: params.orderCode || "" , language: language }).then(
      (data) => {
        if (data && data.data) {
          console.log("dispatch");
          console.log({ data });

          setOrderTypeHook(data.data.order.type);

          if (data.data.order.type === "PickupDelivery") {
            console.log("in to navigate");
            navigate(`/details/${params.orderCode}`, { replace: true });
          }

          new LocalData().set(
            localStorageConst.accessToken,
            data.data.accessToken
          );

          dispatch(
            setCustomer({
              customerId: data.data.order.customerId,
              name: data.data.order.customer.name,
              type: data.data.order.customer.type,
              language: data.data.language,
              avatarPhoto: data.data.order.customer.avatarPhoto,
            })
          );

          dispatch(setOrderType(data.data.order.type));
          dispatch(setOrderId(data.data.order.id));

          dispatch(
            setOrderStatus({
              status: data.data.order.status,
              statusDescription:
                data.data.order.statusDescription[data.data.language],
            })
          );

          if (data.data.order.driver) {
            dispatch(
              setOrderDriver({
                avatarPhoto: data.data.order.driver.avatarPhoto,
                name: data.data.order.driver.name,
                phone: data.data.order.driver.phone,
                rate: data.data.order.driver.rate,
                vehicle: {
                  plate: data.data.order.driver.vehicle.plate,
                  color: data.data.order.driver.vehicle.color,
                  model: data.data.order.driver.vehicle.model,
                },
              })
            );
          }

          dispatch(
            setSetting({
              map: data.data.setting.applicationMap,
              restServerUrl: data.data.setting.restServerUrl,
              socketServerUrl: data.data.setting.socketServerUrl,
              trackDriverTimer: data.data.setting.customerTimer.trackDriverTimer,
            })
          );

          if (
            data.data.order.type == "Ondemand" &&
            data.data.order.pickup &&
            data.data.order.dropoffs
          ) {
            dispatch(
              setPickup({
                lat: data.data.order.pickup.coordinates[1],
                lng: data.data.order.pickup.coordinates[0],
                address: data.data.order.pickup.address,
              })
            );
            const dropoffs = data.data.order.dropoffs.map((d: any) => {
              return {
                lat: d.coordinates[1],
                lng: d.coordinates[0],
                address: d.address,
              };
            });
            dispatch(setDropOffs(dropoffs));
          }

          if (
            data.data.order.type == "PickupDelivery" &&
            data.data.order.pickup &&
            data.data.order.delivery
          ) {
            dispatch(
              setPickup({
                lat: data.data.order.pickup.coordinates[1],
                lng: data.data.order.pickup.coordinates[0],
                address: data.data.order.pickup.address,
              })
            );

            dispatch(
              setDropOffs([
                {
                  lat: data.data.order.delivery.coordinates[1],
                  lng: data.data.order.delivery.coordinates[0],
                  address: data.data.order.delivery.address,
                },
              ])
            );
          }

          if (data.data.order.type == "Pickup" && data.data.order.pickup) {
            dispatch(
              setPickup({
                lat: data.data.order.pickup.coordinates[1],
                lng: data.data.order.pickup.coordinates[0],
                address: data.data.order.pickup.address,
              })
            );
          }

          if (data.data.order.type == "Delivery" && data.data.order.dropoff) {
            dispatch(
              setDropOffs([
                {
                  lat: data.data.order.dropoff.coordinates[1],
                  lng: data.data.order.dropoff.coordinates[0],
                  address: data.data.order.dropoff.address,
                },
              ])
            );
          }

          if (
            data.data.order.type !== "Ondemand" &&
            data.data.events &&
            data.data.events.length > 0
          ) {
            const events = data.data.events.map((event) => ({
              title: event.title[data.data.language],
              description: event.description[data.data.language],
              createdAt: event.createdAt,
            }));
            dispatch(setOrderEvents(events));
          }

          if (data.data.order.service) {
            let options = [];
            options = data.data.order.service.options.map((opt) => ({
              title: opt.title,
              type: opt.type,
              dataTitle: opt.dataTitle,
            }));

            dispatch(
              setService({
                title: data.data.order.service.title[data.data.language],
                icon: data.data.order.service.icon,
                options: options,
              })
            );
          }

          if (data.data.order.vehicleType) {
            let options = [];
            options = data.data.order.vehicleType.options.map((opt: any) => ({
              title: opt.title[data.data.language],
              type: opt.type,
              dataTitle: opt.dataTitle,
            }));

            dispatch(
              setVehicle({
                title: data.data.order.vehicleType.title[data.data.language],
                icon: data.data.order.vehicleType.icon,
                options: options,
              })
            );

            setVehicleTypeIcon(data.data.order.vehicleType.icon);
          }


          dispatch(setRealETA(data.data.order.realETA) );

          setLoading(false);
        }
      }
    );
  }, []);

  if (loading) return <Loader/>;

  if (OrderType === "PickupDelivery") return <span>Redirect...</span>;

  return (
    <>
      <MemoMainHeader/>
      <MemoMapSection />
      <MemoETA />
      <MemoMainBottom
        orderCode={params.orderCode || ""}
        vehicleTypeIcon={vehicleTypeIcon}
      />
    </>
  );
};

export default Main;
