import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  googleApplicationMapService,
  osmApplicationMapService,
} from "@app/types/setting";

export interface SettingState {
  map: googleApplicationMapService | osmApplicationMapService | null;
  restServerUrl: string,
  socketServerUrl: string,
  trackDriverTimer: number;
}

const initialState: SettingState = {
  map: null,
  restServerUrl: '',
  socketServerUrl: '',
  trackDriverTimer: 5000
};

export const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    setMap: (state, action: PayloadAction<SettingState["map"]>) => {
      state.map = action.payload;
    },
    setRestServerUrl: (state, action: PayloadAction<SettingState["restServerUrl"]>) => {
      state.restServerUrl = action.payload;
    },
    setSocketServerUrl: (state, action: PayloadAction<SettingState["socketServerUrl"]>) => {
      state.socketServerUrl = action.payload;
    },
    setSetting: (state, action: PayloadAction<SettingState>) => {
      state.map = action.payload.map;
      state.restServerUrl = action.payload.restServerUrl;
      state.socketServerUrl = action.payload.socketServerUrl;
      state.trackDriverTimer = action.payload.trackDriverTimer;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setMap , setRestServerUrl , setSocketServerUrl , setSetting } = settingSlice.actions;

export default settingSlice.reducer;
